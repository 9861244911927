import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import i18next from 'i18next'

import { useAuthByCode } from 'hooks/useAuthByCode'
import { Item } from './Item/Item'
import { BarsRightIcon } from '../icon/BarsRightIcon'

import './Header.scss'

export const Header = () => {
  const [code, setCode] = useState('')
  useAuthByCode(code)

  const location = useLocation()

  const navigateSignInPage = () => {
    const redirectUrl = `${document.location.origin}`

    window.location.href = `https://pbxv.eu.auth0.com/authorize?response_type=code&client_id=WdeXRGXpJpc2BaYWxfR80vKB1QuASSmE&state=STATE&redirect_uri=${redirectUrl}&audience=https://pbxv.eu.auth0.com/api/v2/&scope=offline_access`
  }

  useEffect(() => {
    if (location.search) {
      const params = Object.fromEntries(new URLSearchParams(location.search))
      setCode(params.code)
    }
  }, [location])

  return (
    <nav className='navbar'>
      <div className='flex w-full justify-center items-center font-sans py-10'>
        <div className='max-w-layout w-full header-container grid grid-cols-4 px-3'>
          <div className='flex justify-start nav-elements'>
            <Item title={i18next.t('header.home')} link='/' />
            {/* <Item title={i18next.t('header.pages')} link='/pages' /> */}
            <Item title={i18next.t('header.shop')} link='/shop' />
          </div>
          <div className='col-span-2 flex justify-center items-center logo-text text-navy-100'>
            LOGO
          </div>
          <div className='justify-end grid grid-cols-3 gap-5 nav-elements'>
            <Item title={i18next.t('header.cart')} link='/cart' />
            <div className='flex items-center'>
              <button
                aria-label='menu'
                type='button'
                className='bg-transparent border-transparent font-sans cursor-pointer'
                onClick={navigateSignInPage}
              >
                SIGN IN
              </button>
            </div>
            <div className='flex items-center'>
              <button
                aria-label='menu'
                type='button'
                className='bg-transparent border-transparent cursor-pointer p-0 m-0'
              >
                <BarsRightIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
