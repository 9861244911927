import { Hero } from './component/Hero/Hero'
import { Categories } from './component/Categories/Categories'
import { MainProduct } from './component/MainProduct/MainProduct'
import { NewArrivals } from './component/NewArrivals/NewArrivals'
import { ContactUs } from './component/ContactUs/ContactUs'

import './Main.scss'

export const Main = () => (
  <div>
    <Hero />
    <Categories />
    <MainProduct />
    <div className='main-full-size-image w-full' />
    <NewArrivals />
    <ContactUs />
  </div>
)
