import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { QueryClientProvider } from '@tanstack/react-query'
import i18n from 'locale/i18n'

import { Header } from 'component/Header/Header'
import { Footer } from 'component/Footer/Footer'
import { Main } from 'screen/Main/Main'
import { Error404 } from 'screen/Error404/Error404'
import { ProductDetail } from 'screen/ProductDetail/ProductDetail'
import { Shop } from 'screen/Shop/Shop'
import { route } from 'config/route'
import { queryClient } from 'config/reactQuery'

import './App.scss'

const { main, shop, shopPagination, cart, product } = route

const PageLayout = () => (
  <>
    <Header />
    <Outlet />
    <Footer/>
  </>
)

export const App = () => (
  <I18nextProvider i18n={i18n}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route element={<PageLayout />}>
            <Route path={main} element={<Main />} />
            <Route path={shop} element={<Shop />} />
            <Route path={shopPagination} element={<Shop />} />
            {/* <Route path={pages} element={<div> PAGES </div>} /> */}
            <Route path={cart} element={<div> CART </div>} />
            <Route path={product} element={<ProductDetail />} />
          </Route>
          <Route path='*' element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </I18nextProvider>
)
