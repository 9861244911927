import './CircleText.scss'

export const CircleText = ({ className }: { className: string }) => (
  <svg className={`${className} rounded-svg`} viewBox='0 0 100 100' width='100' height='100'>
    <defs>
      <path
        id='circle'
        d='
        M 50, 50
        m -37, 0
        a 37,37 0 1,1 74,0
        a 37,37 0 1,1 -74,0'
      />
    </defs>
    <text fontSize='12' fontFamily='Fahkwang, sans-serif'>
      <textPath xlinkHref='#circle'>ERROR 404</textPath>
    </text>
    <text fontSize='12' fontFamily='Fahkwang, sans-serif'>
      <textPath xlinkHref='#circle' startOffset='33.33%'>
        ERROR 404
      </textPath>
    </text>
    <text fontSize='12' fontFamily='Fahkwang, sans-serif'>
      <textPath xlinkHref='#circle' startOffset='66.66%'>
        ERROR 404
      </textPath>
    </text>
  </svg>
)
