import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa'

const SocialMedia = (): JSX.Element => {
  return (
    <div className="flex items-center justify-center w-full mt-10">
      <div className="grid grid-cols-3 gap-8">
        <a href="https://www.facebook.com/"
           className="rounded-full border-solid border border-gray-100 flex items-center justify-center h-16 w-16"
           aria-label="facebook" target="_blank" rel="noreferrer">
          <FaFacebookF className="text-navy-100 p-3 text-xl not-italic font-normal leading-5" />
        </a>
        <a href="https://www.instagram.com"
           className="rounded-full border-solid border border-gray-100 flex items-center justify-center h-16 w-16"
           aria-label="instagram" target="_blank" rel="noreferrer">
          <FaInstagram className="text-navy-100 p-3 text-xl not-italic font-normal leading-5" />
        </a>
        <a href="https://www.tiktok.com/"
           className="rounded-full border-solid border border-gray-100 flex items-center justify-center h-16 w-16"
           aria-label="tiktok" target="_blank" rel="noreferrer">
          <FaTiktok className="text-navy-100 p-3 text-xl not-italic font-normal leading-5" />
        </a>
      </div>
    </div>
  )
}

export default SocialMedia