import { IoStar } from 'react-icons/io5'

type ReviewCardPropsType = {
  review: {
    id: number
    rating: number
    content: string
    date: string
    datetime: string
    author: string
    avatarSrc: string
  }
}

const ReviewCard = ({ review }: ReviewCardPropsType): JSX.Element => {
  return (
    <div className='p-9 mt-9 border-hr border-solid'>
      <div className='flex flex-row justify-between flex-wrap'>
        <div className='flex flex-row'>
          <div className='flex items-center justify-center'>
            <img
              src={review.avatarSrc}
              alt={review.avatarSrc}
              className='h-20 w-20 rounded-full bg-gray-100'
            />
          </div>
          <div className='flex justify-center items-start flex-col ml-4'>
            <span className='text-navy font-fahkwang not-italic text-2xl font-normal leading-7'>
              {review.author}
            </span>
            <span className='text-gray font-sans uppercase leading-5 font-medium not-italic text-sm mt-1'>
              {review.datetime}
            </span>
          </div>
        </div>
        <div className='mt-4'>
          {[0, 1, 2, 3, 4].map((rating) => (
            <IoStar
              key={rating}
              className={`${review.rating > rating ? 'text-gold' : 'text-gray'} size-4 px-1 flex-shrink-0`}
              aria-hidden='true'
            />
          ))}
        </div>
      </div>
      <h2 className='mt-4 text-gray font-sans not-italic text-base font-normal leading-6'>
        {review.content}
      </h2>
      <div className='flex justify-end'>
        <p className='m-0 p-0 pb-2 border-x-0 border-t-0 border-b-navy-100 border-solid w-10 cursor-pointer'>
          Reply
        </p>
      </div>
    </div>
  )
}

export default ReviewCard
