import { useQuery } from '@tanstack/react-query'
import { getAuthByCode } from '../service/authService'

export const useAuthByCode = (code?: string) =>
  useQuery({
    queryKey: ['auth-by-code', code],
    queryFn: async () => getAuthByCode(code),
    select: (response) => response.data,
    enabled: !!code,
  })
