import { useQuery } from '@tanstack/react-query'
import { FACILITY_TOKEN } from 'utils/token'
import { getFacility } from '../service/facilityService'

export const useGetFacility = () =>
  useQuery({
    queryKey: ['facility', FACILITY_TOKEN],
    queryFn: async () => getFacility(FACILITY_TOKEN),
    select: (response) => response?.data || {},
  })
