import { ArrowRightIcon } from '../icon/ArrowRightIcon'
import './Button.scss'

export const Button = ({
  label,
  onClick,
  className,
  isSubmit,
}: {
  label: string
  onClick?: () => void
  className?: string
  isSubmit?: boolean
}) => (
  <button
    type={isSubmit ? 'submit' : 'button'}
    className={`w-fit app-button bg-navy-100 text-white rounded-full p-2 border-0 cursor-pointer ${className}`}
    onClick={onClick}
  >
    <div className='flex justify-center items-center'>
      <span>{label}</span>
      <div className='pl-3 flex justify-center items-center'>
        <ArrowRightIcon />
      </div>
    </div>
  </button>
)
