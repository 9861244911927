import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './en.json'
import ro from './ro.json'
import ru from './ru.json'
import uk from './uk.json'

export const locales = {
  english: 'en',
  romanian: 'ro',
  russian: 'ru',
  ukrainian: 'uk',
}

export const resources = {
  [locales.english]: { translation: en },
  [locales.romanian]: { translation: ro },
  [locales.russian]: { translation: ru },
  [locales.ukrainian]: { translation: uk },
} as const

export const defaultLocale = locales.english

export const getLocale = (): string => i18n.language

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: ['navigator'],
    },
    fallbackLng: defaultLocale,
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  })

export default i18n
