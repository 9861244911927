import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { StarRate } from '../../component/StarRate/StarRate'
import { ButtonGroup } from '../../component/ButtonGroup/ButtonGroup'
import { Button } from '../../component/Button/Button'
import { ProductInformation } from './component/ProductInformation'
import { useGetProductById } from '../../hooks/useGetProductById'
import { ProductImageType } from '../../type/product'

import './ProductDetail.scss'

export const ProductDetail = (): JSX.Element => {
  const { productId } = useParams()

  const { data } = useGetProductById({ productId })

  const [primeImage, setPrimeImage] = useState<ProductImageType | undefined>(undefined)

  const oldPrice = 129.99
  const sizeList = ['XS', 'S', 'M', 'L']
  const [productAmount, setProductAmount] = useState(1)

  useEffect(() => {
    if (data) setPrimeImage(data?.images[0])
  }, [data])

  if (!data) {
    return <></>
  }

  return (
    <div className='product-detail items-center w-full grid-cols-2 flex flex-col'>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-12 px-3 max-w-layout w-full'>
        <div className='p-4 md::p-0'>
          {primeImage ? (
            <div
              className='product-main-img mb-8 bg-contain bg-center bg-no-repeat'
              style={{ backgroundImage: `url(${primeImage.url})` }}
            />
          ) : (
            <div className='product-main-img bg-gray-100 mb-8' />
          )}
          <div className='grid grid-cols-1 md:grid-cols-3 gap-1 md:gap-4'>
            {data.images.map((image: ProductImageType) => (
              <div
                key={image.id}
                className={`h-48 bg-cover bg-center bg-no-repeat ${image.id === primeImage?.id ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                style={{ backgroundImage: `url(${image.url})` }}
                onClick={() => setPrimeImage(image)}
              />
            ))}
          </div>
        </div>
        <div className='h-full flex flex-col p-4 sm:p-0'>
          <div className='flex'>
            <StarRate rate={5} />
            <div className='ml-3'>Reviews 2</div>
          </div>

          <div className='product-detail-title mt-6 mb-4'>{data.name}</div>
          <div className='product-detail-subtitle'>{data.description}</div>

          <div className='flex justify-start mt-12'>
            {oldPrice && (
              <div className='product-detail-old-price text-center mr-4'>${oldPrice}</div>
            )}
            <div className='product-detail-price text-navy-100 text-center'>${data.price}</div>
          </div>

          <div className='mt-12'>
            <div className='size-label'>Size: select</div>
            <ButtonGroup list={sizeList} />
          </div>

          <div className='size-label mt-8'>Quantity</div>

          <div className='flex items-center justify-center'>
            <div className=''>
              <div className='my-4'>
                <span className='isolate inline-flex'>
                  <button
                    type='button'
                    className='add-remove-btn btn px-4 py-4'
                    onClick={() => setProductAmount((prevState) => prevState - 1)}
                    disabled={productAmount <= 1}
                  >
                    -
                  </button>
                  <button type='button' className='btn px-4 py-4'>
                    {productAmount}
                  </button>
                  <button
                    type='button'
                    className='add-remove-btn btn px-4 py-4'
                    onClick={() => setProductAmount((prevState) => prevState + 1)}
                  >
                    +
                  </button>
                </span>
              </div>
            </div>

            <Button className='ml-8 flex-1' label='Add to cart' />
          </div>

          <div className='grid grid-cols-3 gap-3 mt-16'>
            <div>Ask a question</div>
            <div>Delivery & Return</div>
            <div>Share</div>
          </div>

          <div className='mt-auto'>
            <div>SKU: 445566</div>
            <div>Category: Bodysuit</div>
            <div> Tags: Bodysuit, bra</div>
          </div>
        </div>
      </div>
      <ProductInformation />
    </div>
  )
}
