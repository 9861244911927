import ReviewCard from './ReviewCard'

const reviews: {
  id: number
  rating: number
  content: string
  date: string
  datetime: string
  author: string
  avatarSrc: string
}[] = [
  {
    id: 1,
    rating: 3,
    content:
      'This icon pack is just what I need for my latest project. There s an icon for just about anything I could ever need. Love the playful look',
    date: 'July 16, 2021',
    datetime: '2021-07-16',
    author: 'Emily Selman',
    avatarSrc:
      'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
  },
  {
    id: 2,
    rating: 5,
    content:
      'Blown away by how polished this icon pack is. Everything looks so consistent and each SVG is optimized out of the box so I can use it directly with confidence. It would take me several hours to create a single icon this good, so it s a steal at this price',
    date: 'July 12, 2021',
    datetime: '2021-07-12',
    author: 'Hector Gibbons',
    avatarSrc:
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
  },
]

const Reviews = (): JSX.Element => {
  return (
    <div>
      <div>
        <h2
          className='font-fahkwang text-2xl md:text-3xl font-normal leading-7 not-italic text-navy m-0 p-0'
          id='review'
        >
          Reviews (2)
        </h2>
      </div>
      <div>
        {reviews && reviews.map((review) => <ReviewCard review={review} key={review.id} />)}
      </div>
    </div>
  )
}

export default Reviews
