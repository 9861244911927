import { useLayoutEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import ProductInformationCard from './ProductInformationCard'
import SizeGuideCard from './SizeGuideCard'
import Reviews from './Reviews'
import LeaveCommentCard from './LeaveCommentCard'

export const ProductInformation = (): JSX.Element => {
  const navigate = useNavigate()
  const { hash } = useLocation()

  useLayoutEffect(() => {
    const element = document.getElementById(hash.substring(1))
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [hash])

  return (
    <div className='max-w-layout w-full bg-transparent h-full mt-8 border-solid border-gray-100'>
      <div
        className='min-h-14 gap-0 sm:grid sm:grid-cols-2 md:grid-cols-4 border-x-0 border-t-0 border-solid border-b-gray-100'
        id='information'
      >
        <h2
          className={`h-full m-0 p-0 flex justify-center items-center w-full font-fahkwang text-xl not-italic font-normal leading-7 border-x-0 border-t-0 border-b-gray-100 border-solid sm:border-r cursor-pointer ${hash === '#product-information' && 'bg-gray-100'}`}
          onClick={() => navigate('#product-information')}
        >
          Description
        </h2>
        <h2
          className={`h-full m-0 p-0 flex justify-center items-center w-full font-fahkwang text-xl not-italic font-normal leading-7 border-x-0 border-t-0 border-b-gray-100 border-solid md:border-r cursor-pointer ${hash === '#size-guide' && 'bg-gray-100'}`}
          onClick={() => navigate('#size-guide')}
        >
          Size Guide
        </h2>
        <h2 className='h-full m-0 p-0 flex justify-center items-center w-full font-fahkwang text-xl not-italic font-normal leading-7 border-x-0 border-t-0 border-b-gray-100 border-solid sm:border-b-0 sm:border-r'>
          Shipping
        </h2>
        <h2
          className={`h-full m-0 p-0 flex justify-center items-center w-full font-fahkwang text-xl not-italic font-normal leading-7 border-x-0 border-t-0 border-b-0 border-solid sm:border-b-0 cursor-pointer ${hash === '#review' && 'bg-gray-100'}`}
          onClick={() => navigate('#review')}
        >
          Review
        </h2>
      </div>
      <ProductInformationCard />
      <SizeGuideCard />
      <div className='grid grid-cols-1 md:grid-cols-2 gap-8 p-4 sm:p-8'>
        <Reviews />
        <LeaveCommentCard />
      </div>
    </div>
  )
}
