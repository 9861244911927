import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'

import { RangeSliderInput } from 'component/RangeSliderInput/RangeSliderInput'
import { ProductCard } from 'component/ProductCard/ProductCard'
import { Pagination } from 'component/Pagination/Pagination'
import { Dropdown } from 'component/Dropdown/Dropdown'
import { useGetProductList } from 'hooks/useGetProductList'
import { useGetCategoryList } from 'hooks/useGetCategoryList'
import { useGetPriceRage } from 'hooks/useGetPriceRage'
import { PRODUCT_LIMIT } from 'utils/utils'
import { ShopProduct } from 'type/product'

import './Shop.scss'

export const Shop = () => {
  const { page: currentPageParam } = useParams()
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(currentPageParam || 1)
  const [sortBy, setSortBy] = useState<string | null>(null)
  const [currentPriceRange, setCurrentPriceRange] = useState<number[] | null>(null)
  const [selectedCategory, setSelectedCategory] = useState<string[]>([])

  const { data: categoryList } = useGetCategoryList()

  const { data: productData } = useGetProductList({
    currentPage: +currentPage,
    sortBy,
    priceRange: currentPriceRange,
    category: selectedCategory,
  })

  const { data: priceRange } = useGetPriceRage()

  const dropdownOptions = [
    { name: 'Default Sorting', value: null },
    { name: 'Sort by name', value: 'name' },
    { name: 'Sort by price', value: 'price' },
    { name: 'Sort by created date', value: 'createdAt' },
  ]

  const onSelectOption = (option: string | null) => {
    setSortBy(option)
    setCurrentPage(1)
  }

  const selectCategory = (categoryId: string) => {
    const existentId = selectedCategory?.find((id: string) => id === categoryId)
    if (existentId) {
      const copy = selectedCategory?.filter((id: string) => id !== categoryId)
      setCurrentPage(1)
      setSelectedCategory(copy)
      return
    }
    const copy = [...selectedCategory, categoryId]
    setCurrentPage(1)
    setSelectedCategory(copy)
  }

  const onRangeChange = (values: number[]) => {
    setCurrentPriceRange(values)
    setCurrentPage(1)
  }

  return (
    <div className='w-full flex justify-center items-center'>
      <div className='max-w-layout w-full mx-3'>
        <div className='flex max-[700px]:flex-col'>
          <div>
            <div>
              <div className='filter-title'>Categories</div>
              <ul className='category-list'>
                {categoryList?.map((category) => (
                  <li key={category.name} onClick={() => selectCategory(category.id)}>
                    <span
                      className={`category-list-item cursor-pointer ${
                        selectedCategory.includes(category.id)
                          ? 'border-x-0 border-t-0 border-b-navy-100 border-solid'
                          : ''
                      }`}
                    >
                      {category.name}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            {priceRange && (
              <div className='mt-[50px]'>
                <div className='filter-title'>Filter by Price</div>
                <RangeSliderInput
                  min={priceRange?.min}
                  max={priceRange?.max}
                  onRangeChange={onRangeChange}
                />
              </div>
            )}
          </div>
          <div className='ml-auto max-[700px]:ml-0'>
            <div className='flex flex-col w-full items-center'>
              {/* <div className='self-start pl-3 mb-8 shop-showing-results '>
               Showing 1-12 of 20 results
               </div> */}
              <div className='self-end mb-4'>
                <Dropdown options={dropdownOptions} onSelectOption={onSelectOption} />
              </div>
              <div className='grid grid-cols-3 gap-8 pl-3 w-fit max-[880px]:grid-cols-2 max-[540px]:grid-cols-1'>
                {productData?.results?.map((product: ShopProduct) => (
                  <ProductCard
                    className='max-[480px]:w-full'
                    key={`product-${product.name}`}
                    product={product}
                  />
                ))}
              </div>
              <div>
                <Pagination
                  onPageChange={(value: number) => {
                    setCurrentPage(value)
                    navigate(`/shop/${value}`)
                  }}
                  totalCount={productData?.meta.itemCount}
                  siblingCount={1}
                  currentPage={+currentPage}
                  pageSize={PRODUCT_LIMIT}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
