const SizeGuideCard = (): JSX.Element => {
  return (
    <div className='p-4 md:p-8'>
      <div>
        <h2
          className='font-fahkwang text-2xl md:text-3xl font-normal leading-7 not-italic text-navy'
          id='size-guide'
        >
          Size Guide
        </h2>
      </div>

      <div className='text-gray font-sans text-base not-italic font-normal leading-6 w-full border-solid border-gray-100 sm:grid sm:grid-cols-3'>
        <h2 className='text-center px-0 py-3 m-0 sm:p-0 h-full flex justify-center items-center w-full border-x-0 border-t-0 border-b-gray-100 border-solid sm:border-b sm:border-r bg-gray-100'>
          Bras
        </h2>
        <h2 className='text-center px-0 py-3 m-0 sm:p-0 h-full flex justify-center items-center w-full border-x-0 border-t-0 border-b-gray-100 border-solid sm:border-b sm:border-r'>
          Bottoms
        </h2>
        <h2 className='text-center px-0 py-3 m-0'>Bodywear</h2>
      </div>

      <div className='mt-8 flow-root'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <table className='min-w-full'>
              <thead>
                <tr className='text-navy-100 font-fahkwang not-italic text-base font-medium leading-5'>
                  <th className='text-left border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    Size
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    XS
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    S
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    Size
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    M
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    L
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    XL
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    XXl
                  </th>
                </tr>
              </thead>
              <tbody className='text-gray font-sans not-italic text-sm font-normal leading-5'>
                <tr>
                  <th className='text-navy-100 font-fahkwang not-italic text-base font-medium leading-5 text-left border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    Bra Size UK & US
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    30C/32B
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    30D/32C/34B
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    32D/34C
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    34D/36C
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    34DD/36D
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    30D/36C
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    34DD/36D/44
                  </th>
                </tr>
                <tr>
                  <th className='text-navy-100 font-fahkwang not-italic text-base font-medium leading-5 text-left border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    Bra Size Europe
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    65C/70B
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    65D/70C/75B
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    70D/75C
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    75D/80C
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    75E/80D/RR3
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    75D/80C/YYT
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    65D/90/75B
                  </th>
                </tr>
                <tr>
                  <th className='text-navy-100 font-fahkwang not-italic text-base font-medium leading-5 text-left border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    Bra Size France & Spain
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    30C/32B
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    80D/85C/90B
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    90E/95D
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    90D/95C
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    85D/90C
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    80D/85C/PP2
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    30C/32B/UU73
                  </th>
                </tr>
                <tr>
                  <th className='text-navy-100 font-fahkwang not-italic text-base font-medium leading-5 text-left border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    Bra Bra Size Australia
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    8C/10B
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    8D/10C/12B
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    10C/10B
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    12DD/14D
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    12D/14C
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    10C/8C
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    10D/12C
                  </th>
                </tr>
                <tr>
                  <th className='text-navy-100 font-fahkwang not-italic text-base font-medium leading-5 text-left border-x-0 w-1/5 border-t-0 border-b-hr border-solid p-2'>
                    Bra Underbust (cm)
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    69-73
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    69-73
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    73-77
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    77-81
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    77-81
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    81-84
                  </th>
                  <th className='text-center border-x-0 border-t-0 border-b-hr border-solid p-2'>
                    84-88
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SizeGuideCard
