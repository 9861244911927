import { Range, getTrackBackground } from 'react-range'
import { useEffect, useState } from 'react'
import { IRenderTrackParams } from 'react-range/lib/types'

import './RangeSliderInput.scss'

const STEP = 1
const MIN = 0
const MAX = 100

type PropType = {
  min: number
  max: number
  onRangeChange: (values: number[]) => void
}

export const RangeSliderInput = ({ min = MIN, max = MAX, onRangeChange }: PropType) => {
  const [values, setValues] = useState<number[]>([min, max])
  const [oldVal, setOldValues] = useState<number[]>([min, max])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (values[0] === oldVal[0] && values[1] === oldVal[1]) return
      onRangeChange(values)
      setOldValues(values)
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [values, onRangeChange, oldVal])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        paddingRight: '20px',
      }}
    >
      <div className='flex items-center justify-center output-container'>
        <div className='output-value'>${values[0]}</div>
        <div className='horizontal-line px-4 mx-3' />
        <div className='output-value'>${values[1]}</div>
      </div>
      <Range
        step={STEP}
        min={min}
        max={max}
        values={values}
        onChange={setValues}
        renderTrack={({ props, children }: IRenderTrackParams) => (
          <div
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '1px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['#C0C6C6', '#2C4C59', '#C0C6C6'],
                  min,
                  max,
                  rtl: false,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '10px',
              width: '10px',
              borderRadius: '4px',
              backgroundColor: '#2C4C59',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        )}
      />
    </div>
  )
}
