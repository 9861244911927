import React from 'react'
import { usePagination, DOTS } from 'hooks/usePagination'

import './Pagination.scss'

export const Pagination = (props: any) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  const lastPage = paginationRange[paginationRange.length - 1]

  return (
    <ul className='pagination-container'>
      <button
        aria-label='btn'
        type='button'
        className='pagination-item'
        disabled={currentPage === 1}
        onClick={onPrevious}
      >
        <div className='arrow left' />
      </button>
      {paginationRange.map((pageNumber: string) => {
        if (pageNumber === DOTS) {
          return (
            <li key={pageNumber} className='pagination-item dots'>
              &#8230;
            </li>
          )
        }

        return (
          <button
            aria-label='btn'
            type='button'
            key={pageNumber}
            className={`pagination-item ${pageNumber === currentPage ? 'selected' : ''}`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        )
      })}
      <button
        aria-label='btn'
        type='button'
        className='pagination-item'
        disabled={currentPage === lastPage}
        onClick={onNext}
      >
        <div className='arrow right' />
      </button>
    </ul>
  )
}
