import { useQuery } from '@tanstack/react-query'
import { useGetFacility } from './useGetFacility'
import { getPriceRange } from '../service/facilityService'

export const useGetPriceRage = () => {
  const facility = useGetFacility()
  return useQuery({
    queryKey: ['price-range', facility?.data?.id],
    queryFn: async () => getPriceRange(facility?.data?.id),
    select: (response) => response?.data || [],
    enabled: !!facility?.data?.id,
  })
}
