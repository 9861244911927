import { useNavigate } from 'react-router-dom'
import { ShopProduct } from 'type/product'
import { StarRate } from '../StarRate/StarRate'

import './ProductCard.scss'

export const ProductCard = ({ className, product }: { product: ShopProduct; className?: string }) => {
  const { name, price, oldPrice, isOnSale, rate } = product

  const navigate = useNavigate()

  return (
    <div className='flex flex-col'>
      <div
        tabIndex={0}
        role='button'
        className={`product-card bg-background relative cursor-pointer ${className}`}
        onClick={() => navigate(`/product/${product.id}`)}
      >
        {isOnSale && (
          <div className='absolute sale-icon rounded-full flex justify-center items-center'>
            Sale
          </div>
        )}

        <div className='product-card-image w-full bg-gray-100' />
        <div className='mt-8'>
          <div className='product-card-title text-navy-100 text-start mb-2'>{name}</div>
          <StarRate rate={rate} />
        </div>

        <div className='flex justify-start mt-4'>
          {oldPrice && <div className='product-card-old-price text-center mr-4'>${oldPrice}</div>}
          <div className='product-card-price text-navy-100 text-center'>${price}</div>
        </div>
      </div>
    </div>
  )
}
