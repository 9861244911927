import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { GoArrowRight } from 'react-icons/go'
import { MdDone } from 'react-icons/md'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'

const SubscribeForm = (): JSX.Element => {
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<{ email: string }>()
  const [signed, setSigned] = useState<boolean>(false)

  async function sendEmail({ email }: { email: string }) {
    console.log(email)
    setSigned(true)
  }

  return (
    <div className="mt-8 flex justify-center items-center">
      <form className="flex flex-col items-center justify-between w-full md:w-1/2 lg:w-1/4 px-4 md:flex-row">
        <div className="w-2/4 mb-4 md:mb-0 md:mr-2">
          <input
            type="email"
            id="EmailAddress"
            placeholder="Email Address"
            disabled={signed}
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
            className={`bg-transparent h-7 p-0 text-gray font-sans text-sm not-italic font-normal leading-5 outline-0 border-t-0 border-l-0 border-r-0 border-b-[1px] border-b-gray-100 ${signed ? 'opacity-80' : ''}`}
          />
          {errors.email &&
            <div className="relative">
              <span className="absolute text-error text-sm">
                Please enter a valid email address
              </span>
            </div>
          }
        </div>
        {signed ?
          <div className="w-full flex items-center justify-center">
            <MdDone />
          </div>
          :
          <>
            {isSubmitting ?
              <div className="w-full flex items-center justify-center">
                <LoadingSpinner textColor="navy" />
              </div>
              :
              <button
                type="button"
                className={`${errors.email ? 'cursor-not-allowed' : 'cursor-pointer'} mt-6 sm:mt-0 bg-transparent border-0 text-navy-100 font-fahkwang text-base not-italic font-medium leading-5 flex justify-center items-center`}
                onClick={handleSubmit(sendEmail)}
              >
                <span
                  // className="border-t-0 border-r-0 border-l-0 border-b-[1px] border-b-gray-100"
                  className="border-x-0 border-t-0 pb-2 border-b-[1px] border-gray border-solid"
                >
                  Subscribe
                </span>
                <div className="ml-2">
                  <GoArrowRight />
                </div>
              </button>
            }
          </>
        }
      </form>
    </div>

  )
}

export default SubscribeForm
