import './Categories.scss'

export const Categories = () => (
  <div>
    <div className='flex justify-center items-center mt-36'>
      <div className='horizontal-line w-full' />
      <div className='separator-categories mx-16 text-nowrap text-navy-100 uppercase'>
        Our categories
      </div>
      <div className='horizontal-line w-full' />
    </div>

    <div className='flex justify-center mt-16 mb-24 px-3'>
      <div className='w-full max-w-layout grid grid-cols-3'>
        <div className='flex justify-center categories-img' />
        <div className='flex flex-col justify-center my-16'>
          <span className='category-option'>Casual Intimates</span>
          <span className='category-option'>Boudoir Lingerie</span>
          <span className='category-option'>Pagama Party</span>
          <span className='category-option'>Casual Intimates</span>
          <span className='category-option'>Boudoir Lingerie</span>
        </div>
        <div className='flex justify-center categories-img categories-shaped-img justify-self-end self-end' />
      </div>
    </div>
  </div>
)
