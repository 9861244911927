import { useQuery } from '@tanstack/react-query'
import { getProductList } from '../service/shoppingService'

type ProductListProp = {
  currentPage: number
  limit?: number
  sortBy?: string | null
  category?: string[] | null
  priceRange?: number[] | null
}

export const useGetProductList = ({
  currentPage,
  limit,
  sortBy,
  category,
  priceRange,
}: ProductListProp) =>
  useQuery({
    queryKey: [`product-list-${currentPage}-${sortBy}`, category, priceRange],
    queryFn: async () => getProductList(currentPage, sortBy, category, priceRange, limit),
    select: (response) => response?.data || [],
  })
