import { useQuery } from '@tanstack/react-query'
import { getProductById } from '../service/shoppingService'

export const useGetProductById = ({ productId }: { productId?: string }) =>
  useQuery({
    queryKey: ['product-detail', productId],
    queryFn: async () => getProductById(productId),
    select: (response) => response?.data || {},
    enabled: !!productId,
  })
