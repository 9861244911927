import { useNavigate } from 'react-router-dom'
import { Button } from 'component/Button/Button'
import { ButtonGroup } from 'component/ButtonGroup/ButtonGroup'
import { ProductCard } from 'component/ProductCard/ProductCard'
import { NavigationArrowButton } from 'component/NavigationArrowButton/NavigationArrowButton'

import './Hero.scss'

export const Hero = () => {
  const sizeList = ['XS', 'S', 'M', 'L']
  const navigate = useNavigate()

  return (
    <div className='hero-container grid justify-items-center px-3'>
      <div className='max-w-layout w-full grid grid-cols-3 gap-16'>
        <div className='col-span-2 grid grid-cols-2 gap-1'>
          <div className='flex flex-col'>
            <div className='hero-main-title-container'>
              <span className='text-title-1'>Satin Pajama Short Set</span>
            </div>
            <p className='text-content-1'>
              Lectus nulla at volutpat diam ut venenatis tellus in metus. Eget dolor morbi non arcu
              risus quis varius quam quisque
            </p>
            <ButtonGroup list={sizeList} />
            <span className='main-price-text text-navy-100 mt-6'>$120.00</span>
            <Button label='Shop now' className='mt-16' onClick={() => navigate('/shop')} />
          </div>
          <div className='w-full h-full bg-gray-100' />
        </div>
        <div className='mt-14'>
          <div className='grid grid-cols-2 gap-4'>
            <ProductCard
              className='max-w-full'
              product={{ name: 'Night Gown with Lace', price: 114 }}
            />
            <ProductCard
              className='max-w-full'
              product={{ name: 'Nude Set with Lace', price: 149 }}
            />
          </div>
          <div className='flex justify-center mt-8'>
            <NavigationArrowButton />
          </div>
        </div>
      </div>
    </div>
  )
}
