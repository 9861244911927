import { useNavigate } from 'react-router-dom'
import { Button } from 'component/Button/Button'
import { CottonIcon } from 'component/icon/CottonIcon'

import './MainProduct.scss'

export const MainProduct = () => {
  const navigate = useNavigate()
  return (
    <div className='bg-background-1 flex justify-center'>
      <div className='w-full max-w-layout grid grid-cols-2 my-36 px-3'>
        <div className='flex flex-col justify-center'>
          <span className='about-title text-navy'>Lingerie With</span>
          <span className='about-title text-navy'>High-Quality Designs</span>
          <span className='text-content-1 about-content-text'>
            Nisl purus in mollis nunc sed id semper risus in. Eu nisl nunc mi ipsum. Leo integer
            malesuada nunc vel risus. Malesuada nunc vel risus commodo viverra maecenas accumsan
            lacus
          </span>
          <div className='flex flex-row items-center mb-8'>
            <CottonIcon />
            <span className='text-content-1 ml-4 mr-8'>Quality Materials</span>
            <span className='text-content-1 '>Free Shipping</span>
          </div>
          <Button label='Shop now' onClick={() => navigate('/shop')} />
        </div>
        <div className='flex justify-end'>
          <div className='about-rounded-img-container'>
            <div className='about-rounded-img rounded-full' />
          </div>
          <div className='about-square-img' />
        </div>
      </div>
    </div>
  )
}
