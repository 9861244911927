import axios from 'axios'
import { FACILITY_TOKEN } from 'utils/token'
import { PRODUCT_LIMIT } from 'utils/utils'
import { Category } from '../type/category'
import { ProductType } from '../type/product'

const api = axios.create({
  baseURL: 'https://dev.api.e-commerce.product.northweb.solutions/',
})

export const getProductList = (
  currentPage: number,
  sortBy?: string | null,
  categories?: string[] | null,
  priceRange?: number[] | null,
  limit = PRODUCT_LIMIT,
) =>
  api.get('/api/product', {
    params: {
      token: FACILITY_TOKEN,
      page: currentPage,
      limit,
      categories,
      sort_by: sortBy,
      order: 'ASC',
      price_range: priceRange,
    },
  })

export const getProductById = (productId?: string) =>
  api.get<ProductType>(`api/product/${productId}`, {
    params: {
      token: FACILITY_TOKEN,
    },
  })

export const getCategoryList = () => api.get<Category[]>(`/api/category?token=${FACILITY_TOKEN}`)
