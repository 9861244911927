import { useForm, SubmitHandler } from 'react-hook-form'
import { Input } from 'component/Input/Input'
import { Button } from 'component/Button/Button'
import { Textarea } from 'component/Textarea/Textarea'
import { Checkbox } from 'component/Checkbox/Checkbox'

import './ContactUs.scss'

type Inputs = {
  name: string
  contact: string
  question: string
  isDataSaved: boolean
}

export const ContactUs = () => {
  const { register, handleSubmit } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data)

  return (
    <div className='w-full flex justify-center items-center'>
      <div className='w-full max-w-layout flex flex-col justify-center py-8 px-3'>
        <div className='contact-us-title mb-4'>Contact Us</div>
        <div className='grid grid-cols-2 gap-2'>
          <div>
            <div className='contact-us-subtitle mb-8 mt-3'>
              Nisl purus in mollis nunc sed id semper risus. Eu nisl nunc mi ipsum integer malesuada
            </div>
            <div className='contact-us-image bg-gray-100' />
          </div>

          <div className='contact-us-form-container'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input className='mb-8' placeholder='Name' id='name' register={register('name')} />

              <Input
                className='mb-8'
                placeholder='Your email or phone number'
                id='email'
                register={register('contact')}
              />

              <Textarea
                className='mb-8'
                placeholder='Question or description'
                id='question'
                register={register('question')}
              />

              <Checkbox
                className='mb-8'
                label='Save my name, email, and website in this browser for the next time I comment.'
                id='question'
                register={register('isDataSaved')}
              />

              <Button label='Send message' isSubmit />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
