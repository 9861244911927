import { useEffect, useRef, useState } from 'react'

import { ArrowDownIcon } from '../icon/ArrowDownIcon'
import './Dropdown.scss'

type DropdownOption = {
  name: string
  value: string | null
}

type DropdownProp = {
  options: DropdownOption[]
  onSelectOption: (sortBy: string | null) => void
}

export const Dropdown = ({ options, onSelectOption }: DropdownProp) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionSelect = (option: DropdownOption) => {
    setSelectedOption(option)
    setIsOpen(false)
    onSelectOption(option.value)
  }

  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (dropdownRef.current && !dropdownRef?.current?.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={dropdownRef} className='dropdown'>
      <div className={`custom-dropdown-container ${isOpen ? 'open' : ''}`}>
        <div className='custom-dropdown-header' onClick={toggleDropdown}>
          <span>{selectedOption?.name || 'Default Sorting'}</span>
          <ArrowDownIcon />
        </div>
      </div>
      {isOpen && (
        <ul className='custom-dropdown-options'>
          {options.map((option) => (
            <li key={option.value} onClick={() => handleOptionSelect(option)}>
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
