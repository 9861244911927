import { UseFormRegisterReturn } from 'react-hook-form'
import './Input.scss'

type InputProp = {
  placeholder: string
  id: string
  register?: UseFormRegisterReturn
  className?: string
}

export const Input = ({ placeholder, id, register, className }: InputProp) => (
  <input
    placeholder={placeholder}
    className={`
      input focus-visible:border-b-navy-100 w-full bg-background max-w-58 pb-3 ${className} 
    `}
    type="text"
    id={id}
    name={id}
    {...register}
  />
)
