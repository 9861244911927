import { useForm } from 'react-hook-form'
import { GoArrowRight } from 'react-icons/go'

interface IForm {
  name: string
  emailOrPhone: string
  comment: string
}

const LeaveCommentCard = (): JSX.Element => {
  const { register, handleSubmit } = useForm<IForm>()

  async function sendEmail({ emailOrPhone, comment, name }: IForm) {
    console.log(emailOrPhone, comment, name)
  }

  return (
    <div className='bg-white-smoke'>
      <div className=''>
        <h2 className='text-navy m-9 font-fahkwang text-3xl not-italic font-normal leading-7 p-0'>
          Leave a comment
        </h2>
        <form className='flex flex-col px-9'>
          <input
            type='text'
            id='name'
            placeholder='Name'
            {...register('name')}
            className='text-gray font-sans text-base font-normal not-italic leading-6 bg-transparent border-x-0 border-t-0 border-b-[1px] border-b-gray-100 pb-3.5 outline-none'
          />
          <input
            type='text'
            id='emailOrPhone'
            placeholder='Your Email or Phone Number'
            {...register('emailOrPhone')}
            className='mt-9 text-gray font-sans text-base font-normal not-italic leading-6 bg-transparent border-x-0 border-t-0 border-b-[1px] border-b-gray-100 pb-3.5 outline-none'
          />

          <textarea
            id='comment'
            {...register('comment')}
            placeholder='Your Comment'
            className='mt-9 text-gray resize-none font-sans text-base font-normal not-italic leading-6 bg-transparent border-x-0 border-t-0 border-b-[1px] border-b-gray-100 pb-24 outline-none'
          />

          <div className='mt-9'>
            <div className='relative flex items-start'>
              <div className='flex h-6 items-center'>
                <input
                  id='comment'
                  placeholder='Your Comment'
                  name='comment'
                  type='checkbox'
                  className='h-4 w-4 rounded-full'
                />
              </div>
              <div className='ml-3 text-sm leading-6'>
                Save my name, email, and website in this browser for the next time I comment.
              </div>
            </div>
          </div>
          <button
            type='button'
            className='w-fit app-button bg-navy-100 text-white rounded-full p-2 border-0 cursor-pointer mt-9'
            onClick={handleSubmit(sendEmail)}
          >
            <div className='flex justify-center items-center'>
              <span>Send Message</span>
              <div className='pl-3 flex justify-center items-center'>
                <GoArrowRight className='h-6 w-6' />
              </div>
            </div>
          </button>
        </form>
      </div>
    </div>
  )
}

export default LeaveCommentCard
