export const BarsRightIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
    <circle cx='20' cy='20' r='20' fill='#2C4C59' />
    <rect
      x='10.375'
      y='14.375'
      width='19.25'
      height='0.75'
      stroke='#E8D7CC'
      strokeWidth='0.75'
      strokeLinecap='square'
    />
    <rect
      x='13.2324'
      y='19.875'
      width='16.3929'
      height='0.75'
      stroke='#E8D7CC'
      strokeWidth='0.75'
      strokeLinecap='square'
    />
    <rect
      x='17.041'
      y='25.375'
      width='12.5833'
      height='0.75'
      stroke='#E8D7CC'
      strokeWidth='0.75'
      strokeLinecap='square'
    />
  </svg>
)
