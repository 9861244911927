import { useNavigate } from 'react-router-dom'
import { Button } from 'component/Button/Button'
import { CircleText } from './component/CircleText/CircleText'

import './Error404.scss'

export const Error404 = () => {
  const navigate = useNavigate()

  return (
    <div className='bg-background-1 w-full h-screen max-h-screen flex flex-col justify-center items-center overflow-hidden relative'>
      <span className='text-title-2'>OOPs</span>
      <span className='text-title-3'>Page not Found</span>
      <span className='text-content-2 error-page-content my-6'>
        The page you are looking for was moved, removed, renamed or never existed
      </span>
      <Button label='Back to Home' onClick={() => navigate('/')} />

      <CircleText className='round-error-svg' />
      <CircleText className='round-error-svg-2' />
    </div>
  )
}
