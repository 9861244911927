import { UseFormRegisterReturn } from 'react-hook-form'
import './Textarea.scss'

type InputProp = {
  placeholder: string
  id: string
  register?: UseFormRegisterReturn
  className?: string
}

export const Textarea = ({ placeholder, id, register, className }: InputProp) => (
  <textarea
    placeholder={placeholder}
    className={`
      textarea-input focus-visible:border-b-navy-100 w-full bg-background max-w-58 pb-3 ${className} 
    `}
    id={id}
    name={id}
    {...register}
  />
)
