import { useState } from 'react'
import './ButtonGroup.scss'

export const ButtonGroup = ({ list }: { list: string[] }) => {
  const [selected, setSelected] = useState('')

  return (
    <div className='my-4'>
      <span className='isolate inline-flex'>
        {list.map((item) => (
          <button
            key={item}
            type='button'
            className={`${selected === item ? 'filled-btn' : 'outlined-btn'} btn-group px-4 py-4`}
            onClick={() => setSelected(item)}
          >
            {item}
          </button>
        ))}
      </span>
    </div>
  )
}
