import { useNavigate } from 'react-router-dom'
import { ProductCard } from 'component/ProductCard/ProductCard'
import { Button } from 'component/Button/Button'
import { useGetProductList } from 'hooks/useGetProductList'
import { ShopProduct } from 'type/product'

import './NewArrivals.scss'

export const NewArrivals = () => {
  const navigate = useNavigate()

  const { data: productList } = useGetProductList({
    currentPage: 1,
    limit: 6,
  })

  return (
    <div className='w-full flex justify-center items-center'>
      <div className='w-full max-w-layout flex flex-col justify-center'>
        <div className='grid grid-cols-3 py-8'>
          <div className='flex flex-col items-center'>
            <div className='statistic-title mb-3'>2761+</div>
            <div className='category-text'>products</div>
          </div>
          <div className='flex flex-col items-center middle-item-stats'>
            <div className='statistic-title mb-3'>30+</div>
            <div className='category-text'>Lingerie Styles</div>
          </div>
          <div className='flex flex-col items-center'>
            <div className='statistic-title mb-3'>99%</div>
            <div className='category-text'>Satisfied Customer</div>
          </div>
        </div>

        <span className='text-title-4 pb-8 pt-16'>New Arrivals</span>

        <div className='flex flex-col w-full items-center'>
          <div className='grid grid-cols-3 gap-8 px-3 w-fit'>
            {productList?.results?.map((product: ShopProduct) => (
              <ProductCard key={`product-${product.name}`} product={product} />
            ))}
          </div>
        </div>

        <div className='flex justify-center'>
          <Button label='Shop now' className='my-16' onClick={() => navigate('/shop')} />
        </div>
      </div>
    </div>
  )
}
