import SubscribeForm from './component/SubscribeForm'
import SocialMedia from './component/SocialMedia'
import { route } from '../../config/route'

export const Footer = (): JSX.Element => {
  const { main, shop } = route

  return (
    <footer className="h-[954px] w-full bg-background-1" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>

      <div className="hidden md:block">
        <div className="flex items-center justify-center bg-background">
          <div className="grid grid-cols-3 h-[271px] max-w-layout w-full">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-between flex-col mt-[55px]">
                <h3 className="text-navy font-fahkwang text-xl not-italic font-normal leading-7">
                  Convenient Payment
                </h3>
                <span className="text-center text-gray font-sans text-base not-italic font-normal leading-6">
                In mollis nunc sed id semper risus. In aliquam sem fringilla ut morbi
              </span>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-between flex-col mt-[55px]">
                <h3 className="text-navy font-fahkwang text-xl not-italic font-normal leading-7">
                  Free Shipping
                </h3>
                <span className="text-center text-gray font-sans text-base not-italic font-normal leading-6">
              Justo nec ultrices dui sapien eget proin libero. Nibh ipsum consequat
            </span>
              </div>
            </div>

            <div className="flex items-center justify-center">
              <div className="flex items-center justify-between flex-col mt-[55px]">
                <h3 className="text-navy font-fahkwang text-xl not-italic font-normal leading-7">
                  Customer Loyalty Program
                </h3>
                <span className="text-center text-gray font-sans text-base not-italic font-normal leading-6">
              Condimentum id venenatis a vitae pellentesque habitant
            </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-36">
        <h2 className="text-center text-navy font-fahkwang text-2xl not-italic font-normal leading-7">
          Subscribe to Our Newsletter
        </h2>
        <p className="mt-4 text-center text-gray font-sans text-base not-italic	font-normal leading-6">
          Stay up to date with our latest news and events
        </p>
      </div>

      <SubscribeForm />

      <div className="flex items-center justify-center w-full mt-16">
        <div className="grid grid-cols-2 gap-24">
          <h2 className="text-gray font-sans text-sm not-italic font-medium leading-5 uppercase cursor-pointer">
            <a href={main} className="no-underline text-gray">HOME</a>
          </h2>
          {/* <h2 className="font-sans text-sm not-italic font-medium leading-5 uppercase cursor-pointer"> */}
          {/*  <a href="https://tailwindcss.com/docs/width" className="no-underline text-gray">PAGES</a> */}
          {/* </h2> */}
          <h2 className="text-gray font-sans text-sm not-italic font-medium leading-5 uppercase cursor-pointer">
            <a href={shop} className="no-underline text-gray">SHOP</a>
          </h2>
          {/* <h2 className="text-gray font-sans text-sm not-italic font-medium leading-5 uppercase cursor-pointer"> */}
          {/*  <a href="https://tailwindcss.com/docs/width" className="no-underline text-gray">BLOG</a> */}
          {/* </h2> */}
        </div>
      </div>

      <SocialMedia />

      <div className="mx-8 sm:mx-24 mt-16 sm:mt-24 border-solid border-t border-0 border-hr"/>

      <h2 className="text-center mt-10 text-gray font-sans text-sm not-italic font-normal leading-5	">
        Merkulove © Jantel Template All rights reserved Copyrights 2023
      </h2>

    </footer>

  )
}
