import './NavigationArrowButton.scss'
import { ArrowRightIcon } from '../icon/ArrowRightIcon'
import { ArrowLeftIcon } from '../icon/ArrowLeftIcon'

export const NavigationArrowButton = ({
  onClickLeft,
  onClickRight,
}: {
  onClickLeft?: () => void
  onClickRight?: () => void
}) => (
  <div className='flex my-4'>
    <button
      aria-label='left'
      type='button'
      className='arrow-btn rounded-full border-0 bg-transparent mr-4'
      onClick={onClickLeft}
    >
      <div className='flex justify-center'>
        <ArrowLeftIcon color='#2C4C59' />
      </div>
    </button>
    <button
      aria-label='right'
      type='button'
      className='arrow-btn rounded-full border-0 bg-transparent ml-4'
      onClick={onClickRight}
    >
      <div className='flex justify-center'>
        <ArrowRightIcon color='#2C4C59' />
      </div>
    </button>
  </div>
)
