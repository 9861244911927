import { UseFormRegisterReturn } from 'react-hook-form'
import './Checkbox.scss'

type InputProp = {
  label: string
  id: string
  register?: UseFormRegisterReturn
  className?: string
}

export const Checkbox = ({ label, id, register, className }: InputProp) => (
  <div className={className}>
    <input
      className='checkbox bg-background mr-2'
      type='checkbox'
      id={id}
      name={id}
      {...register}
    />
    <label htmlFor={id} className='text-gray checkbox-label'>
      {label}
    </label>
  </div>
)
