import { BsArrowRight } from 'react-icons/bs'

const ProductInformationCard = (): JSX.Element => {
  return (
    <div className='p-4 md:p-8'>
      <h2
        className='font-fahkwang text-2xl md:text-3xl font-normal leading-7 not-italic text-navy'
        id='product-information'
      >
        Product Information
      </h2>
      <p className='text-gray font-sans text-base not-italic font-normal leading-6'>
        Dignissim convallis aenean et tortor at risus viverra. Enim sed faucibus turpis in eu mi.
        Leo integer malesuada nunc vel risus commodo viverra. In dictum non consectetur a. Eget
        lorem dolor sed viverra ipsum nunc. Vitae congue mauris rhoncus aenean vel elit scelerisque
        mauris. Tellus elementum sagittis vitae et leo duis ut. Scelerisque viverra mauris in
        aliquam. Faucibus a pellentesque sit amet porttitor eget.
      </p>
      <p className='text-gray font-sans text-base not-italic font-normal leading-6'>
        In hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit.Hac habitasse
        platea dictumst vestibulum rhoncus est. Vulputate eu scelerisque felis imperdiet proin
      </p>

      <div className='md:grid md:grid-cols-2 text-navy-100 font-fahkwang text-base font-medium not-italic leading-5 p-0 m-0'>
        <p className='flex items-center justify-start'>
          <BsArrowRight />
          <span className='ml-4'>Our signature feather lace</span>
        </p>
        <p className='flex items-center justify-start'>
          <BsArrowRight />
          <span className='ml-4'>We tailor solutions for you</span>
        </p>
        <p className='flex items-center justify-start'>
          <BsArrowRight />
          <span className='ml-4'>Full lace, highwaist string with scalloped edges</span>
        </p>
        <p className='flex items-center justify-start'>
          <BsArrowRight />
          <span className='ml-4'>High-rise fit that hits just below the belly button</span>
        </p>
        <p className='flex items-center justify-start'>
          <BsArrowRight />
          <span className='ml-4'>Thin, hidden elastic at waist</span>
        </p>
        <p className='flex items-center justify-start'>
          <BsArrowRight />
          <span className='ml-4'>Soft silicon care label = no itchy tags</span>
        </p>
      </div>

      <div className='flex items-center justify-start font-sans text-base not-italic leading-6'>
        <h3 className='text-gray font-medium'>Model wears size S:</h3>
        <p className='text-navy-100 font-normal'>
          Height 5’9/175cm, Lower hip 99cm, Waist 63cm, Bra size: UK 30DD/32D
        </p>
      </div>
    </div>
  )
}

export default ProductInformationCard
